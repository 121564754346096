<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
            Let's answer some questions
          </h2>
          <b-card-text class="mb-2">
            or choose a category to quickly find the help you need
          </b-card-text>

          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                placeholder="Search faq..."
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >

        <!-- payment tab -->
        <b-tab
          v-for="(categoryObj, categoryName, index) in faqData"
          :key="categoryName"
          :active="!index"
        >

          <!-- title -->
          <template #title>
            <feather-icon
              :icon="categoryObj.icon"
              size="18"
              class="mr-1"
            />
            <span class="font-weight-bold">{{ categoryObj.title }}</span>
          </template>

          <app-faq-question-answer :options="categoryObj" />
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>You still have a question?</h2>
          <b-card-text class="mb-3">
            If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly!
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MessageCircleIcon"
                size="18"
              />
            </b-avatar>
            <h4>Live Chat</h4>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              size="sm"
              variant="outline-primary"
              @click="openChat()"
            >
              Start Chat
            </b-button>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MailIcon"
                size="18"
              />
            </b-avatar>
            <h4>support@webtoappconvert.com</h4>
            <span class="text-body">Best way to contact us!</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>

import {
  BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BTabs, BTab, BImg, BRow, BCol, BAvatar, BButton,
} from 'bootstrap-vue'
import AppFaqQuestionAnswer from './AppFaqQuestionAnswer.vue'

const data = {
  faqData: {
    // build
    build: {
      icon: 'ShoppingBagIcon',
      title: 'Builds',
      subtitle: 'Questions about app builds?',
      qandA: [
        {
          question: 'How long would it take to complete the build?',
          ans:
              'It would depend on the app size and its features, typically it takes less than few minutes if the servers are not busy. you can check the progress of the build on the view app details page',
        },
        {
          question:
              'What to do if an build fails?',
          ans:
              'Check the build timeline in the app details page to find what went wrong, try again after fixing the issues, if the issue persists you can contact us any time',
        },
        {
          question: 'Can we reuse builds?',
          ans:
              'If the build is successfully completed it would be considered as a spent build, you can not reuse that build again. But if build get failed it will be added back to your remaining builds and you can use it again.',
        },
        {
          question: 'Does builds get expired?',
          ans:
              'Your builds won`t be expired. They will be kept until you use them. But subscription based builds will be cancelled after the subscription period.',
        },
        {
          question: 'What are the outputs after the build?',
          ans:
              'You will receive a zip file containing APK file of the app, signed release App Bundle (.AAB) file, and the key file used for signing (for new/custom keys only).',
        },
        {
          question: 'Which file should I upload to Google Play Console?',
          ans:
              'Always use the signed release App Bundle (.AAB) file for Google Play Store publishing.',
        },
        {
          question: 'How can I update an existing app in Play Store?',
          ans:
              'Firstly, create and modify the app, then click on Build App, in the build options page, make sure to give same package name of your existing app and give higher values for version code and version name. Then select custom key option for app signing. And upload the key file you used for signing the existing app.',
        },
      ],
    },
    // payment
    payment: {
      icon: 'CreditCardIcon',
      title: 'Payment',
      subtitle: 'Any issue regarding payments?',
      qandA: [
        {
          question: 'What are the available payment options?',
          ans:
              'You can pay with your Credit/Debit card or PayPal balance, Also you will be able to Pay with Apple Pay and Google Pay if you select Stripe as the payment gateway in checkout page',
        },
        {
          question: 'My payment was declined,What should I do?',
          ans:
              'Most common cause would be the bank has blocked the transaction, So better to contact the bank. Also you can contact us for any clarification',
        },
        {
          question: 'Can you get a refund for remaining builds?',
          ans:
              'No, No refunds will be given after a purchase. Your remaining builds will be there to use in future',
        },
        {
          question: 'Does my subscription automatically renew?',
          ans:
            'No. your subscriptions will not renew automatically, you have to extend your subscription by buying it for required number of months',
        },
        {
          question: 'How to cancel my subscription?',
          ans:
            'You does not need to cancel subscription as it won`t renew automatically every month. You have to manually renew the subscription',
        },
      ],
    },
    // cancellation and return
    cancellationReturn: {
      icon: 'RefreshCwIcon',
      title: 'Cancellation & Return',
      subtitle: 'Need to cancel?',
      qandA: [
        {
          question: 'Can I cancel an ongoing build?',
          ans:
            'No, You can not cancel a build once it`s sent for building. You will have to wait until the build finish',
        },
        {
          question: 'How to cancel my subscription?',
          ans:
              'You does not need to cancel subscription as it won`t renew automatically every month. But if you want to cancel an existing subscription please contact us.',
        },
        {
          question: 'Can I reuse failed builds?',
          ans:
              'Yes. Failed builds will not be reduced from your remaining build count.',
        },
      ],
    },
    // my orders
    myOrders: {
      icon: 'PackageIcon',
      title: 'My Orders',
      subtitle: 'Problems about your orders?',
      qandA: [
        {
          question: 'How can I view my transactions?',
          ans:
            'You can view your recent transactions on Purchases page',
        },
      ],
    },
    // product and services
    productServices: {
      icon: 'SettingsIcon',
      title: 'Features and Configurations',
      subtitle: 'Features and configurations available',
      qandA: [
        {
          question: 'How can i change the details and other configurations?',
          ans:
            'You can edit or add/remove features form the edit app page,but some features will only be applied for certain build types',
        },
        {
          question: 'How to keep logged in user information even after app close without asking to login again and again',
          ans:
              'Go to edit app page and under Basic information tab enable `Preserve Cookies` option. Also you should disable `Enable Cache` option',
        },
        {
          question: 'What is deep linking and how to use it ?',
          ans:
              'Enabling deep linking option will make your app as the default app for your website url. So when user clicks on a website url, it will be opened through the app instead of the web browser',
        },
        {
          question: 'How can I obtain AdMob Ids for setting up advertisements?',
          ans:
              'You have to sign up with  Google AdMob (https://admob.google.com/home/) service and create required IDs in Google AdMob Dashboard.',
        },
        {
          question: 'How can I obtain OneSignal App Id for setting up notifications?',
          ans:
              'You have to set up an OneSignal account (https://onesignal.com/) to obtain the app ID. For this you will also need to setup a Firebase cloud messaging account (https://firebase.google.com/products/cloud-messaging)',
        },
      ],
    },
  },
  metaInfo: {
    // Children can override the title.
    title: 'Contact Support',
    titleTemplate: '%s | Web To App Convert',
    // Define meta tags here.
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'Convert any website to Android Application for free. FAQ and Contact us via live chat and email.' },
      { name: 'keywords', content: 'Web To App, Convert Web To Android, Android, Website to App, Wordpress to Android, App, Free App Maker, Free, Convert, Website To app, Contact us, Support, Live Chat, Email us, FAQ' },
      // OpenGraph data (Most widely used)
      { property: 'og:title', content: 'Contact Support | Web To App Convert' },
      { property: 'og:site_name', content: 'Web To App Convert' },
      // The list of types is available here: http://ogp.me/#types
      { property: 'og:type', content: 'website' },
      // Should the the same as your canonical link, see below.
      { property: 'og:url', content: 'https://www.webtoappconvert.com/contact' },
      { property: 'og:image', content: 'https://www.webtoappconvert.com/img/logo.png' },
      // Often the same as your meta description, but not always.
      { property: 'og:description', content: 'Convert any website to Android Application for free.' },
      // Twitter card
      { name: 'twitter:card', content: 'Convert any website to Android Application for free.' },
      { name: 'twitter:site', content: 'https://www.webtoappconvert.com/contact' },
      { name: 'twitter:title', content: 'Contact Support | Web To App Convert' },
      { name: 'twitter:description', content: 'Convert any website to Android Application for free.' },
      // Your twitter handle, if you have one.
      // { name: 'twitter:creator', content: '@alligatorio' },
      { name: 'twitter:image:src', content: 'https://www.webtoappconvert.com/img/logo.png' },

      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Contact Support | Web To App Convert' },
      { itemprop: 'description', content: 'Convert any website to Android Application for free.' },
      { itemprop: 'image', content: 'https://www.webtoappconvert.com/img/logo.png' },
    ],
  },
}

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    AppFaqQuestionAnswer,
    BButton,
  },
  data() {
    return {
      faqSearchQuery: '',
      faqData: {},
    }
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    fetchData() {
      const q = this.faqSearchQuery
      const queryLowered = q.toLowerCase()
      const filteredData = {}
      Object.entries(data.faqData).forEach(entry => {
        const [categoryName, categoryObj] = entry
        // eslint-disable-next-line arrow-body-style
        const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
          return qAndAObj.question.toLowerCase().includes(queryLowered)
        })
        if (filteredQAndAOfCategory.length) {
          filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
        }
      })

      this.faqData = filteredData
    },
    openChat() {
      window.open('https://tawk.to/chat/62c73b30b0d10b6f3e7b5492/1g7d3ue79', 'popup', 'width=600,height=600,resizable=no')
    },
  },
  metaInfo: {
    // Overwrite the SEO title.
    title: 'Contact Us',
    meta: [
      { name: 'description', content: 'Convert any website to Android Application for free. Contact us for more information.' },
    ],
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
